import React from "react";
import ReactMarkdown from "react-markdown";
import { useViewPortSize } from "../../hooks";
import { stackStyled, useStackStyletron } from "../../theme";

const NSH1 = stackStyled(
  "h1",
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: "Noto Serif",
    fontSize: props.isMobile ? "31px" : `44px`,
    fontWeight: 900,
    color: props.color,
    textAlign: props.isMobile ? "left" : "center",
    paddingBottom: props.isMobile ? "14px" : "60px",
  })
);

const TWH2 = stackStyled(
  "h1",
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: "Titillium Web",
    fontSize: props.isMobile ? "22px" : `30px`,
    fontWeight: 700,
    color: props.color,
    textAlign: "left",
  })
);

const TWH3 = stackStyled(
  "h1",
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: "Titillium Web",
    fontSize: props.isMobile ? "19px" : `26px`,
    fontWeight: 700,
    color: props.color,
    textAlign: "left",
  })
);

const TWParagraph2 = stackStyled(
  "p",
  (props: { color: string; isMobile?: boolean; margin?: any }) => ({
    fontFamily: "Titillium Web",
    display: "inline-block",
    margin: props.margin,
    fontSize: props.isMobile ? "17px" : `20px`,
    fontWeight: 400,
    color: props.color,
    textAlign: "justify",
  })
);

export const TWA = stackStyled(
  "a",
  (props: { color: string; isMobile?: boolean; margin?: any }) => ({
    fontFamily: "Titillium Web",
    display: "inline-block",
    margin: props.margin,
    fontSize: props.isMobile ? "17px" : `20px`,
    fontWeight: 400,
    color: props.color,
    textDecoration: "underline",
    textAlign: "justify",
  })
);

interface Ibody {
    body: string;
}

const ReactMarkdownComponent = (props: Ibody) => {
    const [isMobile, isTablet] = useViewPortSize();
    const [css, theme] = useStackStyletron();
    
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => (
          <NSH1
            isMobile={isMobile}
            color={theme.stackOnColors.primaryBlack}
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <TWH2
            isMobile={isMobile}
            color={theme.stackOnColors.primaryBlack}
            {...props}
          />
        ),
        h3: ({ node, ...props }) => (
          <TWH3
            isMobile={isMobile}
            color={theme.stackOnColors.primaryGreen}
            {...props}
          />
        ),
        p: ({ node, ...props }) => (
          <TWParagraph2
            isMobile={isMobile}
            color={theme.stackOnColors.primaryBlackLight}
            {...props}
          />
        ),
        ol: ({ node, ordered, ...props }) => (
          <TWParagraph2
            isMobile={isMobile}
            color={theme.stackOnColors.primaryBlackLight}
            {...props}
          />
        ),
        a: ({ node, ...props }) => (
          <TWA
            margin={0}
            isMobile={isMobile}
            color={theme.stackOnColors.primaryGreen}
            {...props}
          />
        ),
      }}
    >
      {props.body}
    </ReactMarkdown>
  );
};

export default ReactMarkdownComponent;